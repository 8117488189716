import { Product } from "./productInterface";

const featuredProducts: Product[] = [
  {
    productID: "aew-all-atlantic-heavyweight-wrestling-championship-belt",
    productName: "AEW All Atlantic Championship Belt",
    productSubtitle: "",
    productImage: require("../images/products/belts/AEW All Atlantic Championship Belt/s-l1600.jpg"),
    productPrice: 150.0,
    productDescription: ``,
    productCategory: "aew",
    reviews: [""],
  },
  {
    productID: "american-heavyweight-wrestling-championship-belt",
    productName: "AMERICAN HEAVYWEIGHT WRESTLING CHAMPION BELT",
    productSubtitle: "",
    productImage: require("../images/products/belts/AMERICAN HEAVYWEIGHT WRESTLING CHAMPION BELT/348ca9df-40a2-4026-a4f2-8cc1ecf6dd96.jpg"),
    productPrice: 150.0,
    productDescription: ``,
    productCategory: "aew",
    reviews: [""],
  },
  {
    productID: "impact-tagteam-heavyweight-wrestling-championship-belt",
    productName: "IMPACT TAGTEAM CHAMPION WRESTLING BELT",
    productSubtitle: "",
    productImage: require("../images/products/belts/IMPACT  TAGTEAM CHAMPION WRESTLING BELT/49ad8ad1-d580-4216-afa1-f091d8d6d3fb.jpg"),
    productPrice: 150.0,
    productDescription: ``,
    productCategory: "aew",
    reviews: [""],
  },
  {
    productID: "nwa-mid-atlantic-states-wrestling-championship-belt",
    productName: "NWA MID ATLANTIC STATES WRESTLING BELT",
    productSubtitle: "",
    productImage: require("../images/products/belts/NWA MID ATLANTIC STATES WRESTLING BELT/1f12d070-274b-4127-be6a-31dcd60c5655.jpg"),
    productPrice: 150.0,
    productDescription: ``,
    productCategory: "aew",
    reviews: [""],
  },
  {
    productID: "roh-jay-briscoe-world-championship-belt",
    productName: "ROH JAY BRISCOE WORLD CHAMPION BELT",
    productSubtitle: "",
    productImage: require("../images/products/belts/ROH JAY BRISCOE WORLD CHAMPIN BELT/d23e86a7-40ce-4a5c-9e66-e20d529a6d1a.jpg"),
    productPrice: 120.99,
    productDescription: ``,
    productCategory: "aew",
    reviews: [""],
  },
  {
    productID: "the-four-horsemen-wrestling-belt",
    productName: "THR FOUR HORSEMEN WRESTLING BELT",
    productSubtitle: "",
    productImage: require("../images/products/belts/THR FOUR HORSEMEN WRESTLING BELT/15589729-2d9a-4319-9d9c-a62ccdd517f6.jpg"),
    productPrice: 150.0,
    productDescription: ``,
    productCategory: "aew",
    reviews: [""],
  },
  {
    productID: "wcw-world-heavyweight-wrestling-championship-belt",
    productName: "WCW WORLD HEAVYWEIGHT WRESTLING CHAMPION BELT",
    productSubtitle:
      "AEW International Heavyweight Wrestling Championship Belt",
    productImage: require("../images/products/belts/WCW WORLD HEAVYWEIGHT WRESTLING CHAMPION BELT/a5993f75-c6ed-4cf5-b847-086f90166abd.jpg"),
    productPrice: 150.0,
    productDescription: ``,
    productCategory: "aew",
    reviews: [""],
  },
  {
    productID: "wwf-wing-eagle-wresling-belt",
    productName: "WWF WING EAGLE WRESTLING BELT",
    productSubtitle:
      "AEW International Heavyweight Wrestling Championship Belt",
    productImage: require("../images/products/belts/WWF WING EAGLE WRESTLING BELT/1831bc94-4fb1-478c-bbde-a66d61128ef4.jpg"),
    productPrice: 150.0,
    productDescription: ``,
    productCategory: "aew",
    reviews: [""],
  },
];

export default featuredProducts;
