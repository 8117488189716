import { Product } from "./productInterface";

const customProducts: Product[] = [
  {
    productID: "golf-championship-belt-gold",
    productName: "Golf Championship Belt",
    productSubtitle: "",
    productImage: require("../images/custom-belts/trophysmack-golf-championship-belt-gold-30796095127613_1200x1200.webp"),
    productPrice: 150.0,
    productDescription: `A heavyweight should never settle for one of those weak title belts you’ve seen floating around the web. Our belts crush the competition. We provide the most unique and easy to design options available to customize your belt for any champion.`,
    productCategory: "custom",
    reviews: [""],
  },
  {
    productID: "worlds-greatest-father-belt",
    productName: "World's Greatest Father Championship Belt",
    productSubtitle:
      "Is there a father in your life that deserves to be recognized?",
    productImage: require("../images/custom-belts/trophysmack-world-s-greatest-father-championship-belt-31038292426813_1024x1024.webp"),
    productPrice: 150.0,
    productDescription: `Finally, a gift he doesn't already have! Our solid 6lb metal belts will impress even the oldest jaded grandpas out there. We provide the most unique and easy-to-design options available. Chose from our classic design or upload an image and add your own text to personalize the perfect belt.`,
    productCategory: "custom",
    reviews: [""],
  },
  {
    productID: "e-sports-championship-belt-gold",
    productName: "Esports Championship Belt",
    productSubtitle: "",
    productImage: require("../images/custom-belts/trophysmack-esports-championship-belt-gold-30796131598397_1200x1200.webp"),
    productPrice: 150.0,
    productDescription: `A heavyweight should never settle for one of those weak title belts you’ve seen floating around the web. Our belts crush the competition. We provide the most unique and easy to design options available to customize your belt for any champion.`,
    productCategory: "custom",
    reviews: [""],
  },
  {
    productID: "mma-6lb-customizable-championship-belt",
    productName: "MMA 6lb Customizable Championship Belt",
    productSubtitle: "",
    productImage: require("../images/custom-belts/trophysmack-mma-6lb-customizable-championship-belt-30794002497597_1200x1200.webp"),
    productPrice: 150.0,
    productDescription: `A heavyweight should never settle for one of those weak title belts you’ve seen floating around the web. Our belts crush the competition. We provide the most unique and easy to design options available to customize your belt for any champion.`,
    productCategory: "custom",
    reviews: [""],
  },
  {
    productID: "college-basketball-bracket-championship-belt-gold",
    productName: "Bracket Championship Belt",
    productSubtitle: "",
    productImage: require("../images/custom-belts/trophysmack-college-basketball-bracket-championship-belt-gold-30796115345469_1200x1200.webp"),
    productPrice: 150.0,
    productDescription: `A heavyweight should never settle for one of those weak title belts you’ve seen floating around the web. Our belts crush the competition. We provide the most unique and easy to design options available to customize your belt for any champion.    `,
    productCategory: "custom",
    reviews: [""],
  },
  {
    productID: "custom-championship-belt",
    productName: "Ultimate 6lb Custom Championship Belt",
    productSubtitle: "",
    productImage: require("../images/custom-belts/trophysmack-ultimate-6lb-custom-championship-belt-30797497794621_1200x1200.webp"),
    productPrice: 150.0,
    productDescription: `A heavyweight should never settle for one of those weak title belts you’ve seen floating around the web. Our belts crush the competition. We provide the most unique and easy to design options available to customize your belt for any champion.    `,
    productCategory: "custom",
    reviews: [""],
  },
  {
    productID: "blinged-out-fantasy-football-championship-belt",
    productName: "Ultimate Blinged-Out Championship Belt",
    productSubtitle: "",
    productImage: require("../images/custom-belts/trophysmack-ultimate-blinged-out-championship-belt-31023417524285_1024x1024.jpg"),
    productPrice: 150.0,
    productDescription: `Presenting the BIGGEST and blingiest Fantasy Football Championship Belt on the planet! Blind your foes with your greatness by wearing this monster around town. Thousands of not-really real diamonds (exactly 3,169 of them, maybe) encrust this belt from end to end. There’s no escaping its glory!    `,
    productCategory: "custom",
    reviews: [""],
  },
  {
    productID: "golf-swing-customizable-championship-belt",
    productName: "Golf Swing 6lb Customizable Championship Belt",
    productSubtitle: "",
    productImage: require("../images/custom-belts/trophysmack-golf-swing-6lb-customizable-championship-belt-31422676172861_1200x1200.jpg"),
    productPrice: 150.0,
    productDescription: ``,
    productCategory: "custom",
    reviews: [""],
  },
];

export default customProducts;
